@font-face {
  font-family: lifecraft;
  font-style: normal;
  font-weight: 400;
  src: local("LifeCraft_Font"),
  url("./fonts/LifeCraft_Font.ttf") format("truetype");
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logo {
  font-family: lifecraft;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #61dafb;
  font-size: 3.5rem;
}
.github {
  justify-content: flex-end;
  display: flex;
  padding:1.5rem;
  img {
    height: 3rem;
    width: 3rem;
  }
}
.discord {
  width: 18rem;
}

main.main {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex:1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
